import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/tutorials.tsx";
export const pageTitle = "Running a service";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TutorialSteps = makeShortcode("TutorialSteps");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "running-a-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#running-a-service",
        "aria-label": "running a service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Running a service`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#what-you-need"
        }}>{`What you need`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#1-implement-the-service"
        }}>{`1. Implement the service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#2-override-service-methods"
        }}>{`2. Override service methods`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#3-create-a-server"
        }}>{`3. Create a server`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#4-run-the-server"
        }}>{`4. Run the server`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#whats-next"
        }}>{`What's next`}</a></li>
    </ul>
    <p>{`In this step, we'll do three things with the code we obtained from our Thrift file; define the blog service in Java, create a server instance, and run the service.`}</p>
    <TutorialSteps current={2} mdxType="TutorialSteps" />
    <h2 {...{
      "id": "what-you-need",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-you-need",
        "aria-label": "what you need permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What you need`}</h2>
    <p>{`You need to have the `}<a parentName="p" {...{
        "href": "/tutorials/thrift/blog/define-service#3-compile-the-thrift-file"
      }}>{`generated Java code`}</a>{` obtained from the previous step.
You can always `}<a parentName="p" {...{
        "href": "https://github.com/line/armeria-examples/tree/main/tutorials/thrift"
      }}>{`download`}</a>{` the full version, instead of creating one yourself.`}</p>
    <h2 {...{
      "id": "1-implement-the-service",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#1-implement-the-service",
        "aria-label": "1 implement the service permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1. Implement the service`}</h2>
    <p>{`Create a file, `}<inlineCode parentName="p">{`BlogServiceImpl.java`}</inlineCode>{`, and declare the `}<inlineCode parentName="p">{`BlogServiceImpl`}</inlineCode>{` class implementing the `}<inlineCode parentName="p">{`BlogService`}</inlineCode>{` service we defined earlier in `}<a parentName="p" {...{
        "href": "/tutorials/thrift/blog/define-service"
      }}>{`Step 1. Define a service`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceImpl.java",
        "filename": "BlogServiceImpl.java"
      }}>{`package example.armeria.server.blog.thrift;

import example.armeria.blog.thrift.BlogService;

public class BlogServiceImpl implements BlogService.AsyncIface {}
`}</code></pre>
    <h2 {...{
      "id": "2-override-service-methods",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#2-override-service-methods",
        "aria-label": "2 override service methods permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`2. Override service methods`}</h2>
    <p>{`Add empty service methods as follows to override the service methods.
We'll implement the service methods one by one in this tutorial.
Let's leave them empty for now.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java",
        "metastring": "filename=BlogServiceImpl.java",
        "filename": "BlogServiceImpl.java"
      }}>{`import example.armeria.blog.thrift.BlogPost;
import example.armeria.blog.thrift.CreateBlogPostRequest;
import example.armeria.blog.thrift.GetBlogPostRequest;
import example.armeria.blog.thrift.ListBlogPostsRequest;
import example.armeria.blog.thrift.ListBlogPostsResponse;
import example.armeria.blog.thrift.UpdateBlogPostRequest;
import example.armeria.blog.thrift.DeleteBlogPostRequest;
import org.apache.thrift.TException;
import org.apache.thrift.async.AsyncMethodCallback;

public class BlogServiceImpl implements BlogService.AsyncIface {

  @Override
  public void createBlogPost(CreateBlogPostRequest request, AsyncMethodCallback<BlogPost> resultHandler)
          throws TException {}

  @Override
  public void getBlogPost(GetBlogPostRequest request, AsyncMethodCallback<BlogPost> resultHandler)
          throws TException {}

  @Override
  public void listBlogPosts(ListBlogPostsRequest request, AsyncMethodCallback<ListBlogPostsResponse> resultHandler)
          throws TException {}

  @Override
  public void updateBlogPost(UpdateBlogPostRequest request, AsyncMethodCallback<BlogPost> resultHandler)
          throws TException {}

  @Override
  public void deleteBlogPost(DeleteBlogPostRequest request, AsyncMethodCallback<Void> resultHandler)
          throws TException {}
}
`}</code></pre>
    <h2 {...{
      "id": "3-create-a-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#3-create-a-server",
        "aria-label": "3 create a server permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`3. Create a server`}</h2>
    <p>{`Let's create a server to serve our service.`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Create the `}<inlineCode parentName="p">{`Main`}</inlineCode>{` class for the server.
You can see the full version of the file `}<a parentName="p" {...{
            "href": "https://github.com/line/armeria-examples/tree/main/tutorials/thrift/src/main/java/example/armeria/server/blog/thrift/Main.java"
          }}>{`here`}</a>{`.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`package example.armeria.server.blog.thrift;

import org.slf4j.Logger;
import org.slf4j.LoggerFactory;

public final class Main {
  private static final Logger logger = LoggerFactory.getLogger(Main.class);
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the `}<inlineCode parentName="p">{`newServer()`}</inlineCode>{` method in the `}<inlineCode parentName="p">{`Main`}</inlineCode>{` class as follows.
We are using Armeria's `}<a parentName="p" {...{
            "href": "type://THttpService:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/thrift/THttpService.html"
          }}>{`type://THttpService`}</a>{` to handle Thrift calls.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`import com.linecorp.armeria.server.Server;
import com.linecorp.armeria.server.thrift.THttpService;
...
private static Server newServer(int port) throws Exception {
  final THttpService tHttpService =
            THttpService.builder()
                        .addService(new BlogServiceImpl())
                        .build();
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Create and return a server instance using Armeria's `}<a parentName="p" {...{
            "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
          }}>{`type://ServerBuilder`}</a>{`.
Note that the service instance, `}<inlineCode parentName="p">{`tHttpService`}</inlineCode>{`, is added to the server instance.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`private static Server newServer(int port) throws Exception {
  ...
  return Server.builder()
               .http(port)
               .service("/thrift", tHttpService) // Add the service to server
               .build();
}
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Add the `}<inlineCode parentName="p">{`main()`}</inlineCode>{` method in the `}<inlineCode parentName="p">{`Main`}</inlineCode>{` class as follows.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-java",
            "metastring": "filename=Main.java",
            "filename": "Main.java"
          }}>{`public static void main(String[] args) throws Exception {
  final Server server = newServer(8080);

  server.closeOnJvmShutdown().thenRun(() -> {
    logger.info("Server has been stopped.");
  });

  server.start().join();
}
`}</code></pre>
      </li>
    </ol>
    <h2 {...{
      "id": "4-run-the-server",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#4-run-the-server",
        "aria-label": "4 run the server permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`4. Run the server`}</h2>
    <p>{`Run the `}<inlineCode parentName="p">{`Main.main()`}</inlineCode>{` method on your IDE or using Gradle.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`./gradlew run
`}</code></pre>
    <p>{`Your server is running if you see the following message.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`[armeria-boss-http-*:8080] INFO com.linecorp.armeria.server.Server - Serving HTTP at /[0:0:0:0:0:0:0:0]:8080 - http://127.0.0.1:8080/
`}</code></pre>
    <h2 {...{
      "id": "whats-next",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#whats-next",
        "aria-label": "whats next permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What's next`}</h2>
    <p>{`In this step, we've created and added an empty Thrift service to a server.`}</p>
    <p>{`Next, we'll get on with implementing a service method for `}<a parentName="p" {...{
        "href": "/tutorials/thrift/blog/implement-create"
      }}>{`creating blog posts`}</a>{`.`}</p>
    <TutorialSteps current={2} mdxType="TutorialSteps" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      